<template>
  <div>
    <e-filters
      ref="filters"
      :title="$t('Filtros')"
      :searching="fetching"
      :search-text="$t('Pesquisar')"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <b-row>
        <b-col md="5">
          <e-store-combo
            id="store-name"
            v-model="filters.storeId"
            :required="false"
          />
        </b-col>
        <b-col md="4">
          <FormulateInput
            id="period"
            v-model="filters.createdRangeDate"
            type="date-range-picker"
            :time-picker="false"
            :label="$t('Período')"
          />
        </b-col>
      </b-row>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>
      <b-table
        ref="tableSaleItems"
        responsive
        show-empty
        striped
        class="mb-1 bordered"
        :empty-text="
          searched ? $t('Nenhum dado encontrado') : $t('Realize a pesquisa para exibir os dados')
        "
        :fields="fields"
        :items="saleItems"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :per-page="paging.pageSize"
        :current-page="paging.currentPage"
        no-provider-paging
        no-provider-sorting
      >
        <template
          v-for="(field, index) in additionalFields"
          #[`cell(${field.key})`]="{ item, index: colIndex }"
        >
          <span
            :key="`span_${field.key}_${index}_${colIndex}`"
          >{{ item[field.key] | currency }} <br
            :key="field.key"
          ></span>
          <em :key="`em_${field.key}_${index}_${colIndex}`">{{
            percentage(item, item[field.key]) | percentage
          }}</em>
        </template>

        <template #custom-foot>
          <tr>
            <th class="text-right">
              Total
            </th>
            <th
              v-for="(field, index) in additionalFields"
              :key="`total_value_th_${field.key}_${index}`"
              class="text-center"
            >
              <span :key="`total_value_${field.key}_${index}`">{{
                totalByPaymentMethod(field.key) | currency
              }}</span>
            </th>

            <th class="text-center">
              {{ totalValue | currency }}
            </th>
          </tr>
        </template>
      </b-table>
      <b-row class="pt-1">
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="invoices-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>
  </div>
</template>

<script>
import { BRow, BCol, BTable, BPagination } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { formulateValidation } from '@/mixins'
import { mapActions, mapState } from 'vuex'
import { EStoreCombo, EFilters, EPaginationSummary, EPageSizeSelector } from '@/views/components'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    BTable,
    BPagination,
    EFilters,
    EStoreCombo,
    EPaginationSummary,
    EPageSizeSelector,
  },

  mixins: [formulateValidation],

  data() {
    return {
      fetching: false,
      saving: false,
      searched: false,
      sortBy: 'StoreId',
      sortDesc: false,
    }
  },

  computed: {
    ...mapState('pages/reports/sales/saleByPaymentMethod', [
      'saleItems',
      'paging',
      'reportData',
      'filters',
    ]),

    fields() {
      return [
        {
          label: this.$t('Loja'),
          key: 'StoreId',
          sortable: true,
          formatter: (value, index, item) =>
            `${this.$options.filters.padLeft(item.StoreId, 3, '0')} - ${item.StoreName}`,
        },
        ...this.additionalFields,
        this.totalField,
      ]
    },

    additionalFields() {
      if (this.saleItems?.length) {
        const [firstRecord] = this.saleItems
        const columns = Object.keys(firstRecord).slice(2)
        return columns.map(column => ({
          label: column,
          key: column,
          tdClass: 'text-right',
          thStyle: 'width: 150px',
          sortable: true,
          isDynamic: true,
          formatter: value => this.$options.filters.currency(value),
        }))
      }
      return []
    },

    totalField() {
      return {
        label: this.$t('Total'),
        key: 'total',
        tdClass: 'text-right',
        thStyle: 'width: 150px;',
        sortable: true,
        formatter: (value, index, item) => this.$options.filters.currency(this.totalByStore(item)),
      }
    },

    totalByStore() {
      return storeSales => {
        const columns = Object.values(storeSales).slice(2)
        return columns.reduce((prev, curr) => prev + (curr || 0), 0)
      }
    },

    totalsByPaymentMethod() {
      const [firstStore] = this.saleItems
      const totals = {}
      if (firstStore) {
        const paymentMethods = Object.keys(firstStore)
        paymentMethods.forEach(paymentMethod => {
          totals[paymentMethod] = this.saleItems.reduce(
            (prev, curr) => prev + (curr[paymentMethod] || 0),
            0
          )
        })
      }

      return totals
    },

    totalByPaymentMethod() {
      return paymentMethod => this.totalsByPaymentMethod[paymentMethod]
    },

    totalValue() {
      const totals = this.saleItems.reduce((prev, curr) => prev + this.totalByStore(curr), 0)
      return totals
    },

    percentage() {
      return (item, value) => value / this.totalByStore(item)
    },

    dateToFormated() {
      return this.$options.filters.date(this.filters.dateTo)
    },
    today() {
      return new Date()
    },
  },

  mounted() {},

  methods: {
    ...mapActions('pages/reports/sales/saleByPaymentMethod', [
      'fetchSaleItems',
      'generateReport',
      'resetFilters',
      'setPageSize',
      'setCurrentPage',
      'updateRowsInPage',
    ]),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchSaleItems()
          this.searched = true
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    async downloadReport() {
      try {
        if (this.$refs.filters.isValid()) {
          this.fetching = true
          await this.generateReport()
          // Get the download link element from the template
          const { downloadLink } = this.$refs

          if (this.reportData) {
            // Set the URL and download attributes of the link
            downloadLink.href = this.reportData
            downloadLink.download = `VendaPorMeioPagamento-Loja_${this.filters.storeId}-Data_${this.filters.createdRangeDate.startDate}_ate_${this.filters.createdRangeDate.endDate}.xlsx`

            // Click the link to start the download
            downloadLink.click()
          } else {
            this.showError({ message: this.$t('Não foi possível recuperar os dados do relatório') })
          }
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },

    filterData() {
      this.getData()
    },

    resetFiltersLocal() {
      this.resetFilters()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.updateRowsInPage()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
    },
  },
}
</script>

<style lang="scss" scoped></style>
