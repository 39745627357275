var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "e-filters",
        {
          ref: "filters",
          attrs: {
            title: _vm.$t("Filtros"),
            searching: _vm.fetching,
            "search-text": _vm.$t("Pesquisar"),
          },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "5" } },
                [
                  _c("e-store-combo", {
                    attrs: { id: "store-name", required: false },
                    model: {
                      value: _vm.filters.storeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "storeId", $$v)
                      },
                      expression: "filters.storeId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "period",
                      type: "date-range-picker",
                      "time-picker": false,
                      label: _vm.$t("Período"),
                    },
                    model: {
                      value: _vm.filters.createdRangeDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "createdRangeDate", $$v)
                      },
                      expression: "filters.createdRangeDate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card-actions",
        {
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.filterData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            ref: "tableSaleItems",
            staticClass: "mb-1 bordered",
            attrs: {
              responsive: "",
              "show-empty": "",
              striped: "",
              "empty-text": _vm.searched
                ? _vm.$t("Nenhum dado encontrado")
                : _vm.$t("Realize a pesquisa para exibir os dados"),
              fields: _vm.fields,
              items: _vm.saleItems,
              "sort-by": _vm.sortBy,
              "sort-desc": _vm.sortDesc,
              "per-page": _vm.paging.pageSize,
              "current-page": _vm.paging.currentPage,
              "no-provider-paging": "",
              "no-provider-sorting": "",
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.additionalFields, function (field, index) {
                  return {
                    key: "cell(" + field.key + ")",
                    fn: function (ref) {
                      var item = ref.item
                      var colIndex = ref.index
                      return [
                        _c(
                          "span",
                          {
                            key:
                              "span_" +
                              field.key +
                              "_" +
                              index +
                              "_" +
                              colIndex,
                          },
                          [
                            _vm._v(
                              _vm._s(_vm._f("currency")(item[field.key])) + " "
                            ),
                            _c("br", { key: field.key }),
                          ]
                        ),
                        _c(
                          "em",
                          {
                            key:
                              "em_" + field.key + "_" + index + "_" + colIndex,
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("percentage")(
                                  _vm.percentage(item, item[field.key])
                                )
                              )
                            ),
                          ]
                        ),
                      ]
                    },
                  }
                }),
                {
                  key: "custom-foot",
                  fn: function () {
                    return [
                      _c(
                        "tr",
                        [
                          _c("th", { staticClass: "text-right" }, [
                            _vm._v(" Total "),
                          ]),
                          _vm._l(_vm.additionalFields, function (field, index) {
                            return _c(
                              "th",
                              {
                                key:
                                  "total_value_th_" + field.key + "_" + index,
                                staticClass: "text-center",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    key:
                                      "total_value_" + field.key + "_" + index,
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.totalByPaymentMethod(field.key)
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                          _c("th", { staticClass: "text-center" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("currency")(_vm.totalValue)) +
                                " "
                            ),
                          ]),
                        ],
                        2
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          }),
          _c(
            "b-row",
            { staticClass: "pt-1" },
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "invoices-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }